<template>
  <div class="home">
    <headert></headert>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img  src="../../assets/image/banner/Entertainment.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class=" WJgroupIntroduce-Box" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="padding-tb70">
          <div class="WJgroup-titleBox flex">
            <p class="WJgroup-title">{{$t('entertainment.title')}}</p>
          </div>
        </div>

          <ul class="rightBox_info flex">
            <li class="rightBox_infoli flex " v-for="(item,index) in typeList" :key="index" data-wow-delay="0.2s">
              <img :src="item.icon" alt="">
              <div>
                <h4>{{item.typename}}-</h4>
                <h6 v-html="item.seo_title"></h6>
              </div>
            </li>
          </ul>

        <el-row class="Entertainment_Info">
          <el-col :span='8'  v-for="(item,index) in typeList" :key="index">
            <div class="Entertainment_InfoBox" @click="$router.push({path:'/EntertainmentDigitalCinema',query:{id:item.id}})">
              <img :src="item.litpic" alt="">
              <div class="Entertainment_InfoBot flex">
                <h1>{{item.typename}}</h1>
                <h6>{{item.title_en}}</h6>
              </div>
              <router-link :to="{path:'/EntertainmentDigitalCinema',query:{id:item.id}}">
              <div class="Entertainment_Infotop">
                <h1>{{item.typename}}</h1>
                <h6>{{item.title_en}}</h6>
                <p v-html="item.idea"></p>
                <i class="el-icon-circle-plus-outline"></i>
              </div>
              </router-link>
            </div>

          </el-col>

        </el-row>
      </el-col>
    </el-row>
    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },
        slideList: [{
            id: "42",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          },
          {
            id: "45",
            img: require("../../assets/image/banner1.png")
          }
        ],
        menuActiveIndex: '/Entertainment',
        menu: {
          name: '产业布局',
          path: '/IndustryHouse',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name:this.$t('menu.layout'),
          path: '/IndustryHouse',
        }, {
          name: this.$t('menu.entertainment'),
          path: '/Entertainment',
        }, {
          name: this.$t('menu.culture'),
          path: '/Entertainment',
        }],
        typeIds:[109,156,157],
        typeList:[]
      };
    },
    computed: {},
    created() {
      this.getData()
    },
    mounted() {
      new this.$wow.WOW().init()
    },
    methods: {
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        let condition={};
        this.typeIds.forEach(item=>{
          condition['apiType_'+item] =`ekey=${item}&typeid=${item}&addfields=title_en,idea,icon,typename_en,seo_title_en,idea_en,seo_title_en`;
        })
        this.$request.post(api,{
          ...condition
        }).then(res=>{

          let typeList=[];
          for(let typeid in res.apiType){
            if(res.apiType[typeid].data){
              typeList.push(res.apiType[typeid].data)
            }
          }
          this.typeList =this.$utils.translate(typeList);
        })
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .swiperIMg {
    width: 100%;
    height: auto;
  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }

  .house {
    .num {
      font-size: 93px;
      font-family: Impact;
      font-weight: 400;
      color: #af8e68;
    }

    .numRe {
      margin-left: 30px;
    }

    .com {
      display: flex;

      .qualityHouse {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1f2d3a;
        line-height: 40px;

      }

      .eng {
        height: 21px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #aaaaaa;
        margin-left: 11px;
      }
    }

    p {

      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1F2D3A;
      line-height: 24px;
      margin-top: 10px;

    }
  }

  .leftTitleOneActive {
    background: linear-gradient(to right, #e3caa6, #b5956f) !important;
    position: relative;
  }

  .leftTitleOneActive::after {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #fff;
  }

  .leftTitleOne {
    background: linear-gradient(to right, #adafb2, #8f9294);
    // width: 100%;
    padding: 40px 50px;
  }

  .leftTitleOne h4 {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 38px;
  }

  .leftTitleOne h5 {

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 30px;
  }

  .leftTitleOne h6 {
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }

  .rightBox {
    // padding-top: 75px;
  }

  .rightBox_info {
    // margin-left: 55px;
     justify-content: space-between;
  }

  .rightBox_infoli {
    padding: 15px;
    align-items: center;
    width: 33%;
  }

  .rightBox_infoli img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  .rightBox_infoli h4 {

    font-size: 28px;
    font-family: MicrosoftYaHeiLight;
    font-weight: 400;
    color: #1F2D3A;
    line-height: 42px;
  }

  .rightBox_infoli h6 {

    font-size: 18px;
    font-family: Microsoft YaHei;
    // font-weight: 400;
    color: #1F2D3A;
    line-height: 42px;
  }

  .Entertainment_Info {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .Entertainment_InfoBox {
    // width: 100%;
    margin: 0 1px;
    height: 726px;
    position: relative;
    overflow: hidden;
  }

  .Entertainment_InfoBox:hover .Entertainment_InfoBot {
    opacity: 0;
  }

  .Entertainment_InfoBox:hover .Entertainment_Infotop {
    top: 0;
  }

  .Entertainment_InfoBox img {
    width: 100%;
    height: 100%;
  }

  .Entertainment_InfoBot {
    width: 100%;
    height: 130px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
  }

  .Entertainment_InfoBot h1 {

    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;

  }

  .Entertainment_InfoBot h6 {

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 30px;
  }

  .Entertainment_Infotop {
    position: absolute;
    transition: 0.5s;
    top: -730px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    color: #FFFFFF;
  }

  .Entertainment_Infotop h1 {
    line-height: 42px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;

  }

  .Entertainment_Infotop h6 {

    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 30px;
  }

  .Entertainment_Infotop p {
    width: 50%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    padding: 80px 0 60px;
  }

  .Entertainment_Infotop i {
    font-size: 40px;
  }

  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupIntroduce-Box{
      min-height: calc(100vh - 400px);
    }

    .rightBox_infoli{
      flex-direction: column;
      align-items: start;
      h4{
        font-size: 36px;
      }
      h6{
        font-size:22px
      }
      img{
        margin-bottom: 10px;
      }
    }
  }


</style>
